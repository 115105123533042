import React from "react";
import Navbar from "./layouts/Navbar";
import Footer from "./layouts/Footer";
import HeroSection from "./components/core/home/HeroSection";
// import FastGrowingStartups from "./components/core/home/FastGrowingStartups";
import AllAIToolsSection from "./components/core/home/AllAIToolsSection";
import DiscoverUnlimited from "./components/core/home/DiscoverUnlimited";
import ClientSaySection from "./components/core/home/ClientSaySection";
import PricingPlansSection from "./components/core/home/PricingPlansSection";
import FAQSection from "./components/core/home/FAQSection";
import NextOpportunity from "./components/core/home/NextOpportunity";
import QuizComponent from "./components/core/home/QuizzComponent";
import posthog from 'posthog-js'

if (process.env.REACT_APP_ENV === 'production') {
  posthog.init('phc_fB2RR6KfoDeatHt8KvfSHVKJlQOvCKnIIUfp4QKmjyz',
      {
          api_host: 'https://us.i.posthog.com',
          person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
      }
  )
}

function App() {
  return (
    <div className="bg-white">
      <Navbar />
      {/* components st */}
      <HeroSection />
      <QuizComponent />
      {/* <FastGrowingStartups /> */}
      <AllAIToolsSection />
      {/* <DiscoverUnlimited /> */}
      <ClientSaySection />
      {/* <PricingPlansSection /> */}
      <FAQSection />
      {/* <NextOpportunity/> */}
      {/* components end */}
      <Footer />
    </div>
  );
}

export default App;
